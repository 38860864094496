import React from "react";
import s from "./style.module.css";
import Container from "../../../components/Container";
import ContentCard from "../../../components/ContentCard";

import { ReactComponent as Spotlight } from "../../../components/Icons/spotlight.svg";
import { ReactComponent as Blogs } from "../../../components/Icons/blogs.svg";
import { ReactComponent as DataAnalysis } from "../../../components/Icons/dataanalysis.svg";
import { ReactComponent as Report } from "../../../components/Icons/report.svg";
import { ReactComponent as Media } from "../../../components/Icons/media.svg";
import { ReactComponent as Country } from "../../../components/Icons/country.svg";

const RelatedResources = ({ title, items }) => {
  const Icons = {
    spotlight: <Spotlight />,
    theReport: <Report />,
    dataAndAnalysis: <DataAnalysis />,
    background: <Blogs />,
    country: <Country />,
    media: <Media />,
  };

  return (
    <div className={s.related}>
      <Container large>
        <h2 className={s.title}>{title}</h2>
        <div className={s.relatedGrid}>
          {items.map(({ url, icon, title, description }) => (
            <ContentCard
              key={title}
              icon={Icons[icon]}
              to={url}
              title={title}
              description={description}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default RelatedResources;
