import React from "react";
import cn from "classnames";
import s from "./style.module.css";
import { Link } from "react-router-dom";

const ContentCard = ({ to, icon, title, description, alternate }) => {
  return (
    <Link to={to} className={cn(s.wrap, { [s.alternate]: alternate })}>
      {icon && <div className={s.icon}>{icon}</div>}
      {title && <h2 className={s.title}>{title}</h2>}
      {description && <p className={s.description}>{description}</p>}
    </Link>
  );
};

export default ContentCard;
