import React from "react";
import s from "./style.module.css";
import Main from "../../layouts/Main";
import RelatedResources from "./RelatedResources";
import Stories from "../../components/Stories";
import DownloadBanner from "../../components/DownloadBanner";
import Header from "./Header";
const copy = require(`./copy/${process.env.REACT_APP_LANG}`);

const Homepage = () => {
  const {
    meta,
    title,
    intro,
    subTitle,
    relatedResources,
    relatedResourcesTitle,
    banner,
  } = copy;

  return (
    <Main meta={meta}>
      {banner && <DownloadBanner {...banner} />}

      <div className={s.wrap}>
        <Header title={title} intro={intro} subTitle={subTitle} />

        <Stories />

        <RelatedResources
          title={relatedResourcesTitle}
          items={relatedResources}
        />
      </div>
    </Main>
  );
};

export default Homepage;
