import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const Container = ({ children, unrestricted, large }, ref) => {
  return (
    <div
      className={cn(s.container, {
        [s.unrestricted]: unrestricted,
        [s.large]: large,
      })}
    >
      <div className={s.inner}>{children}</div>
    </div>
  );
};

export default Container;
