import React from "react";
import s from "./style.module.css";
import Container from "../../../components/Container";

const Header = ({ title, intro, subTitle }) => {
  return (
    <>
      <div className={s.header}>
        <Container large>
          <h1 className={s.headerText}>
            <span className={s.preTitle}>{subTitle}</span>
            <br />
            {title}
          </h1>
          <div className={s.headerImageWrap}>
            <div className={s.headerImageInner}>
              <img
                width="1152"
                height="1152"
                className={s.headerImage}
                src={`${process.env.PUBLIC_URL}/images/home-header.svg`}
                alt=""
              />
            </div>
          </div>
        </Container>
      </div>

      <div className={s.intro}>
        <Container large>{intro}</Container>
      </div>
    </>
  );
};

export default Header;
