import React from "react";
import s from "./style.module.css";
import { Link } from "react-router-dom";
import cn from "classnames";
import Arrow from "../Arrow";
import slugify from "slugify";

const StoryCard = ({
  id,
  title,
  standfirst,
  colorSubtle,
  reversed,
  layerOne,
  layerTwo,
  block,
  arrow,
}) => {
  return (
    <Link
      className={cn(s.storyBox, { [s.reversed]: reversed, [s.block]: block })}
      to={`/stories/${slugify(title).toLowerCase()}/`}
      style={{
        "--color": colorSubtle,
      }}
    >
      <div className={s.inner}>
        <div className={s.storyImage}>
          <div className={s.imageWrap}>
            <div className={s.backCrop}>
              <img
                className={s.back}
                src={`${process.env.PUBLIC_URL}/images/${id}/header.svg`}
                alt=""
              />
            </div>
          </div>
        </div>

        <div>
          {arrow && (
            <div className={s.icon}>
              <Arrow dir={arrow} bg="#fff" />
            </div>
          )}
          <h2 className={s.storyTitle}>{title}</h2>
          <p className={s.storyIntro}>{standfirst}</p>
        </div>
      </div>
    </Link>
  );
};

export default StoryCard;
