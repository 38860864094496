import React from "react";

import Meta from "../../components/Meta";
import s from "./style.module.css";

const Main = ({ children, meta = {} }) => {
  return (
    <Meta {...meta}>
      <div className={s.wrap}>{children}</div>
    </Meta>
  );
};

export default Main;
