import React from "react";
import s from "./style.module.css";
import { Link } from "react-router-dom";
import Arrow from "../Arrow";

const DownloadBanner = ({ url, text }) => {
  return (
    <Link className={s.wrap} to={url}>
      {text}
      <Arrow dir="right" />
    </Link>
  );
};

export default DownloadBanner;
