import React from "react";
import DocumentMeta from "react-document-meta";
import { useLocation } from "react-router-dom";

const addSocialMeta = (meta, path) => {
  const url =
    process.env.PUBLIC_URL ||
    process.env.DEPLOY_PRIME_URL ||
    "https://wdr2021.worldbank.org";

  const {
    title = "",
    description = "",
    keywords,
    author = "World Development Report 2021 team",
    publisher = "The World Bank Group",
    image = "/images/share.png",
    robots = "index, follow",
  } = meta;

  const socialMeta = {
    "og:title": title,
    "og:description": description,
    "og:image": `${url}${image}`,
    "twitter:card": "summary_large_image",
    "twitter:title": title,
    "twitter:description": description,
    "twitter:image": `${url}${image}`,
  };

  return {
    ...meta,
    canonical: `https://wdr2021.worldbank.org${path !== "/" ? path : ""}`,
    meta: {
      ...meta.meta,
      name: {
        robots,
        keywords,
        author,
        publisher,
      },
      property: {
        ...socialMeta,
      },
    },
  };
};

const Meta = ({ children, ...meta }) => {
  const { pathname } = useLocation();
  const withSocial = addSocialMeta(meta, pathname);

  return <DocumentMeta {...withSocial}>{children}</DocumentMeta>;
};

export default Meta;
