import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import Container from "../Container";
import StoryCard from "../StoryCard";
const copy = require(`../../pages/Homepage/copy/${process.env.REACT_APP_LANG}`);

const Stories = ({ id }) => {
  let { stories } = copy;

  if (id) {
    const current = stories.find((d) => d.id === id);
    const next = stories.find((d) => d.id === current.next);
    const previous = stories.find((d) => d.id === current.previous);

    stories = [next, previous].filter(Boolean).reverse();
  }

  return (
    <div className={cn(s.stories, { [s.gridded]: id })}>
      {stories.map((story, i) => (
        <div
          key={i}
          className={s.story}
          style={{
            "--color": story.colorSubtle,
          }}
        >
          <Container large>
            <StoryCard
              {...story}
              reversed={!(i % 2)}
              block={id}
              arrow={id ? (i % 2 ? "right" : "left") : null}
            />
          </Container>
        </div>
      ))}
    </div>
  );
};

export default Stories;
